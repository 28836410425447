export const home = {
    nav: {
        0: 'DAPP Contract',
        1: 'Block Browser',
        2: 'Wallet',
        3: 'Announcement',
        4: 'History',
        5: 'Whitepaper'
    },
    title: {
        0: 'Project introduction',
        1: 'Performance advantage',
        2: 'Wallet',
        3: 'Block Browser',
        4: 'Submit feedback online',
        5: 'History'
    },
    text: {
        0: 'ETSC create a public chain network with cutting-edge block chain technology',
        1: 'High TPS performance far exceeds the current mainstream Coin',
        2: 'Open source, decentralized application logic rules',
        3: 'Open source cold wallet, and integrate many mainstream ETH currencies, can directly enter the ecology through the wallet entrance',
        4: 'Whole network block data ledger, real-time query',
        5: 'Submit your proposal and application',
        6: 'The development process of ETSC since birth'
    },
    introduction: {
        item: {
            0: 'High throughput',
            1: 'Scalability',
            2: 'High reliability',
            3: 'High security'
        },
        word: {
            0: 'High throughput is based on ETSC2.0 public chain technology. The cross-chain verification and GMC gene matrix algorithm developed by ETSC itself are introduced to greatly improve the TPS performance of ETSC chain, making it reach the scale of more than 10,000 levels, and greatly enhance the concurrency of the network',
            1: 'DAPP smart contract cross-chain verification, ETSC chain can support the contract transplantation of ETH and TRON. Through fully decentralized mapping verification, it can perfectly realize the replication and transfer of user assets, which can be used to accommodate a large number of users',
            2: 'The newly developed memory allocation tool, VDT_SCO, can optimize memory allocation for the whole network result, making the whole structure more reliable',
            3: 'The ETSC public chain has been in stable and normal operation for nearly 3 years, and has not been compromised by any malicious network attack. The reverse verification of spiral multi-chain data makes the possibility of it being cracked by the network almost zero'
        }
    },
    advantage: {
        item: 'High TPS',
        word: 'create a consensus nodes, and each consensus among nodes at the same time the N consecutive blocks, if because the network delay caused by block lost main chain bifurcate, synchronous operation side chain will vote for the main chain according to the security check mechanism, the main chain of the repair bifurcate, according to the voting results and on the basis of security through the high frequency straight out of the blocks to achieve high performance of TPS.'
    },
    dapp: {
        item: {
            0: 'Dice game, DApp throwing results',
            1: 'Casino game, select the result by hashing',
            2: 'Heartbeat game, soar to the sky',
            3: 'ETSC first sub-chain, can be mined through the cold wallet to obtain free tokens',
            4: 'The first subchain on the ETSC public chain to be issued through the community autonomous DAO pattern',
            5: 'Distribution of ecological welfare',
            6: 'Pledge ETSC, custom issuer sub-chain, serving commercial and institutional blockchain projects',
            7: 'Assets are used for community outreach and rewards',
            8: 'ETSC second sub-chain, can be mined through the cold wallet to obtain free tokens',
            9: 'ETSC third sub-chain, can be mined through the cold wallet to obtain free tokens',
            10: 'ETSC fourth sub-chain, can be mined through the cold wallet to obtain free tokens',
            11: 'ETSC fifth sub-chain, can be mined through the cold wallet to obtain free tokens',
            12: 'ETSC sixth sub-chain, can be mined through the cold wallet to obtain free tokens',
        },
        word: {
            0: 'Document download',
            1: 'Community fund',
            2: 'Ecological Application Submission'
        }
    },
    submit: {
        0: 'Name',
        1: 'Call',
        2: 'Miner ID',
        3: 'Wallet address',
        4: 'E-mail',
        5: 'Message'
    },
    course: {
        0: 'ETSC completes the basic architecture design',
        1: 'ETSC launches global POS mechanism to mine and distribute chips',
        2: 'ETSC Cold Wallet was launched, and the first DApp TID online',
        3: 'ETSC mining finished, open the full circulation, the construction of ecological network',
        4: 'Introduce risky financial products SWE',
        5: 'DApp game TID2.0 has been updated',
        6: 'Robust financial products HBH online, sharing public chain benefits',
        7: 'Introduces the on-chain user activity scoring system EPSM',
        8: 'DApp Tyche online',
        9: 'Linking community plate community fund online',
        10: 'ETSC developed the gene matrix algorithm GMC, which greatly improved the performance of TPS',
        11: 'Online the first test Sub-chain ETSL',
        12: 'Start a new marketing model',
        13: 'DApp Moon online',
        14: 'Issue the second sub chain MPL',
        15: 'Issue the third sub chain PPS',
        16: 'Issue the fourth sub chain CMC',
        17: 'Issue the fifth sub chain FCT',
        18: 'Issue the sixth sub chain NBC',
    },
    footer: {
        0: 'Contact',
        1: 'Copyright @2018 ETSC. All Rights Reserved. All rights reserved'
    },
    button: {
        0: 'Entry',
        1: 'Submit'
    },
    message: {
        0: 'Enter the name',
        1: 'Enter the call',
        2: 'Enter the miner ID',
        3: 'Enter the wallet address',
        4: 'Enter the e-mail',
        5: 'Enter the message',
        6: 'Success',
        7: 'Submit successfully',
        8: 'Error'
    }
};

