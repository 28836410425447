import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import {Backtop} from 'element-ui';

// 多语言插件
import VueI18n from 'vue-i18n'

Vue.use(Backtop);

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en-US',
    messages: {
        'zh-CN': require('./assets/js/language/cn/index'), // 中文语言包
        'en-US': require('./assets/js/language/en/index') // 英文语言包
    },
    silentFallbackWarn: true //抑制警告
});
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
