export const home = {
    nav: {
        0: 'DAPP生态',
        1: '区块浏览器',
        2: '钱包',
        3: '公告',
        4: '进程',
        5: '白皮书'
    },
    title: {
        0: '项目简介',
        1: '性能优势',
        2: '钱包',
        3: '区块浏览器',
        4: '在线提交反馈',
        5: '进程'
    },
    text: {
        0: 'ETSC以太明星，打造尖端区块链技术的公链网络',
        1: '高TPS性能远超当前主流币种',
        2: '开源、去中心化的应用逻辑规则',
        3: '开源冷钱包，并集成ETH的多个主流币种，可通过钱包入口直接进入生态',
        4: '全网络区块数据账本，即时查询',
        5: '提交您的建议和申请',
        6: 'ETSC自诞生以来的发展进程'
    },
    introduction: {
        item: {
            0: '高吞吐量',
            1: '可扩展性',
            2: '高可靠性',
            3: '高安全性'
        },
        word: {
            0: '高吞吐量是以ETSC2.0公链技术为基础，引入ETSC自身研发的跨链校验及GMC基因矩阵算法，大幅提升ETSC公链的TPS性能，使其达到万级以上的规模，极大增强了网络的并发性',
            1: '通过DApp智能合约跨链校验，ETSC公链能支持ETH和TRON的合约移植，通过完全去中心化映射校验，完美实现用户资产的复制和转移，可用于支撑大量的用户',
            2: '全新研发的内存分配工具VDT_SCO，可为整个网络结果进行更优化的内存分配，使其整个结构更可靠',
            3: 'ETSC公链稳定正常运行近3年时间，未曾被任何恶意网络攻击行为攻陷过，螺旋多链数据反向的验证，使其被网络破解的可能性几乎为零'
        }
    },
    advantage: {
        item: '高 TPS',
        word: 'ETSC实现高性能TPS的核心点是每隔5秒钟产生一个共识节点，且每个共识节点会同时连续出N个区块，如果因为网络延迟造成区块丢失引起主链分叉，同步运行的侧链会根据安全核对机制对主链进行投票，根据投票结果修复主链分叉，从而在确保安全的基础之上通过高频连续出块来实现高TPS性能。'
    },
    dapp: {
        item: {
            0: '骰子游戏，DApp投掷结果',
            1: '博彩游戏，通过哈希值选取结果',
            2: '心跳游戏，冲上云霄',
            3: 'ETSC第一条子链，可通过冷钱包挖矿免费获取代币',
            4: 'ETSC公链第一条通过社区自治DAO模式发行的子链',
            5: '生态福利发放',
            6: '质押ETSC，定制发行子链，服务于商业和机构的区块链项目',
            7: '资产用于社区推广与奖励',
            8: 'ETSC第二条子链，可通过冷钱包挖矿免费获取代币',
            9: 'ETSC第三条子链，可通过冷钱包挖矿免费获取代币',
            10: 'ETSC第四条子链，可通过冷钱包挖矿免费获取代币',
            11: 'ETSC第五条子链，可通过冷钱包挖矿免费获取代币',
            12: 'ETSC第六条子链，可通过冷钱包挖矿免费获取代币',
        },
        word: {
            0: '资料下载',
            1: '社区基金',
            2: '生态申请提交'
        }
    },
    submit: {
        0: '姓名',
        1: '手机号码',
        2: '矿机编号',
        3: '钱包地址',
        4: '邮箱',
        5: '留言'
    },
    course: {
        0: 'ETSC完成基本架构设计',
        1: 'ETSC开启全球POS机制挖矿分发筹码',
        2: 'ETSC冷钱包上线，第一款DApp游戏TID上线',
        3: 'ETSC挖矿结束，开启全流通，构建生态网络',
        4: '引入风险性理财产品SWE',
        5: 'DApp游戏TID2.0升级上线',
        6: '稳健型理财产品HBH上线，共享公链福利',
        7: '推出链上用户活跃度积分系统EPSM',
        8: 'DApp游戏Tyche上线',
        9: '链接社群的板块社区基金上线',
        10: 'ETSC开发了基因矩阵式算法GMC，极大提升了TPS性能',
        11: '发行第一条测试侧链ETSL',
        12: '启动全新的引流模式',
        13: 'DApp游戏Moon上线',
        14: '发行第二条子链MPL',
        15: '发行第三条子链PPS',
        16: '发行第四条子链CMC',
        17: '发行第五条子链FCT',
        18: '发行第六条子链NBC',
    },
    footer: {
        0: '联系方式',
        1: '版权所有@2018 ETSC。版权所有'
    },
    button: {
        0: '立即前往',
        1: '提交'
    },
    message: {
        0: '请输入姓名',
        1: '请输入手机号码',
        2: '请输入矿机编号',
        3: '请输入钱包地址',
        4: '请输入邮箱',
        5: '请输入留言',
        6: '成功',
        7: '提交成功',
        8: '错误'
    }
};
